.login-logo{
    width: 100%;
}

.login-heading{
    width: 100%;
}

.sidebar-logo{
    width: 95%;
    margin: 0 auto;
}
.plan_box {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 100%;
}